// import logo from '../assets/img/logos/WIF-Dark.png'
import Footer from '../components/common/footer'
import NavBar from '../components/common/navBar'

export default function CodeOfConduct() {
    return (
        <div>
            <NavBar />

            <section id="cod">
                <div class="container" data-aos="fade-up">
                    <div class="section-header">
                        <h3>Code of Conduct</h3>
                    </div>
                    <div class="justify-content-left">
                        <h5>Purpose of Code of Conduct</h5>
                        <p>
                            The purpose of our Community Code of Conduct is to
                            ensure that all participants in the SLIIT Women In
                            FOSS community have the best possible experience. We
                            are all here to help each other learn, grow our
                            skill sets, and have a good time! The SLIIT Women In
                            FOSS Community Code of Conduct covers our behavior
                            as members of the SLIIT Women In FOSS community, in
                            any forum, mailing list, wiki, web site, code
                            repository, IRC channel, private correspondence, or
                            public meetings. As a worldwide community, diversity
                            is one of our huge strengths, but it can also lead
                            to communication issues and unhappiness. To that
                            end, we have a few ground rules that we ask people
                            to adhere to, to ensure that the SLIIT Women In FOSS
                            community continues to be an excellent space in
                            which like-minded individuals are able to
                            communicate, collaborate, and contribute. This isn't
                            an exhaustive list of things that you can't do.
                            Rather, take it in the spirit in which it's intended
                            — a guide to make it easier to be excellent to one
                            another. We expect it to be followed in spirit as
                            much as in the letter.{' '}
                            <i>
                                If you believe someone is violating the Code of
                                Conduct, we ask that you report it by emailing
                                infowifsliit@gmail.com.
                            </i>
                        </p>
                        <h5>Expected Behavior</h5>
                        <ul>
                            <li>
                                <b>Be considerate</b> - Your work will be used
                                by other people, and you in turn will depend on
                                the work of others. Any decision you take will
                                affect users and colleagues, and you should take
                                those consequences into account when making
                                decisions.
                            </li>
                            <li>
                                <b>Be patient</b> - That'll helps you build
                                empathy towards others.
                            </li>
                            <li>
                                <b>Be respectful</b> - Receiving respect from
                                others is important because it helps us to feel
                                safe and to express ourselves.
                            </li>
                            <li>
                                <b> Be nice </b> - This community is formed and
                                visited by like-minded individuals in their own
                                quests for knowledge. Treat them as you would
                                like to be treated.
                            </li>
                            <li>
                                <b>Communicate effectively </b> - Effective
                                communication can help foster a good working
                                relationship with others.
                            </li>
                            <li>
                                <b> Get for help when unsure </b>- Don't be
                                afraid to ask for help from your peers.
                            </li>
                        </ul>
                        <h5>Unacceptable Behaviors</h5>
                        <ul>
                            <li>
                                Violent threats directed against another person.
                            </li>
                            <li>Discriminatory jokes.</li>
                            <li>
                                Posting (or threatening to post) other people's
                                personally identifiable information on social
                                media or somewhere.
                            </li>
                            <li>Personal insults.</li>
                            <li>Unwelcome sexual attention.</li>
                        </ul>
                        <h5>Reporting Guide</h5>
                        <p>
                            If you believe someone is violating the code of
                            conduct, we ask that you report it to the SLIIT
                            Women In FOSS Community by emailing
                            infowifsliit@gmail.com.
                        </p>
                        <ul>
                            <li>
                                Your contact info (So we can stay touch with
                                you).
                            </li>
                            <li>
                                When and where the incident occurred. Please be
                                as specific as possible.
                            </li>
                            <li>
                                Any extra context in relation to the incident.
                            </li>
                            <li>If you believe this incident is ongoing.</li>
                            <li>
                                Any other information you believe we should
                                have.
                            </li>
                        </ul>
                        <h5>What happens after you file a report?</h5>
                        <p>
                            You will receive an email from us immediately. We
                            promise to take action within 24 hours. (We'll try
                            to be quicker than that)
                        </p>
                        <h5>Consequences of Bad Behavior</h5>
                        <p>
                            After gathering and processing sufficient
                            information, we'll take appropriate action. Respond
                            to a Code of Conduct violation will be as follows:
                        </p>
                        <ul>
                            <li>
                                <b>
                                    We'll give the person in question a public
                                    or private warning{' '}
                                </b>
                                and explain how their behavior negatively
                                impacted others.
                            </li>
                            <li>
                                <b>Suspend the person</b> enforced through a
                                temporary ban on participating in any aspect of
                                future community activities.
                            </li>
                            <li>
                                <b>Permanently ban</b> the person from the SLIIT
                                Women In FOSS Community.
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <br />
            <Footer />
        </div>
    )
}
